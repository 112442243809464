import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.1em;  
}

body {
    width: 100%;
    height: 100%;
    background-color: #33ffd4;
    font-size: 1rem;
}

h1 {
    font-size: 24vh;
    font-weight: 100;
    text-transform: uppercase;
    ine-height: 1.8;
    margin-bottom: 2vh;
    word-wrap: break-all;
}

h3 {
  font-size: 12vh;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  word-wrap: break-all;
}


.pagination {
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 2rem;
}

#ContentWrapper{
    width: 100%;
    height: 100%;  
}

.animated-text {
  animation: typing 4s steps(18) forwards;
} 

@keyframes typing {
   from {
      width: 0;
   }
   to {
      width: 18ch;
   }
}

@media only screen and (max-width: 375px) {
  h1 {
    font-size: 8vh;
    font-weight: 100;
    text-transform: uppercase;
    ine-height: 1.8;
    margin-bottom: 2vh;
    word-wrap: break-all;
}
    h3 {
  font-size: 6vh;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  word-wrap: break-all;
}
}

@media only screen and (min-width: 376px) {
  h1 {
    font-size: 10vh;
    font-weight: 100;
    text-transform: uppercase;
    ine-height: 1.8;
    margin-bottom: 2vh;
    word-wrap: break-all;
}
    h3 {
  font-size: 8vh;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  word-wrap: break-all;
}
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 14vh;
    font-weight: 100;
    text-transform: uppercase;
    ine-height: 1.8;
    margin-bottom: 2vh;
    word-wrap: break-all;
}
    h3 {
  font-size: 10vh;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  word-wrap: break-all;
}
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 24vh;
    font-weight: 100;
    text-transform: uppercase;
    ine-height: 1.8;
    margin-bottom: 2vh;
    word-wrap: break-all;
}
    h3 {
  font-size: 18vh;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 0.4rem;
  word-wrap: break-all;
}
}

`;

export default GlobalStyle;
