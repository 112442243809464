import styled from 'styled-components';

export default function Header({ onNavigate }){
    

    return(
        <HeaderContent> 
        <MenuList>
        <MenuLink><MenuAnchor href="#top">Top</MenuAnchor></MenuLink>
        <MenuLink><MenuAnchor href="#imprint">Impressum</MenuAnchor></MenuLink>
        </MenuList>
        </HeaderContent>
    )

}

//Styled Components
    const HeaderContent = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 1.8em;
    background-color: #0f0f0f;
    opacity: 0.78;
    padding-top: 0.4em;
    `
    const MenuList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
    margin-left: 1em;
    margin-right: 1em;
    `
    const MenuLink = styled.li`
    color: #88e7ee;
    list-style: none;
    `
    const MenuAnchor = styled.a`
    color: #88e7ee;
    text-decoration: none;
    `